
import SignInRecoveryModelUtils from "./SignInRecoveryModelUtils.js";

import StringUtils from '@/utils/StringUtils.js';
import WebLogger from "@/domain/logger/WebLogger.js";
import ConstUtils from '@/utils/ConstUtils.js';

import EventUtils from '@/utils/EventUtils.js';

import CredentialEvents from '@/domain/model/events/CredentialEvents.js';
import EventsDomain from '@/domain/model/events/EventsDomain.js';
import { uuid } from 'vue-uuid'

export default class SignInRecoveryModel {

  static Fields = {
    SendButton: "send",
    BackButton: "back",
  }

  static Values = {
    States: {
      Ready: "ready",
      Sending: "sending",
      Done: "done",
    }
  }

  constructor(panel, credentials) {
    this._panel = panel;
    this._state = SignInRecoveryModel.Values.States.Ready;
    this._credentials = credentials;
    this._tableData = [];
    this._domain = this.panel().domain;
    this._logger = new WebLogger(window.console, true);
    this._showMissingRequirements = false;
    this._error = {
      messageId: "",
      hint: ""
    };
    this._timer = null;
    this._status = "";
    this._checklistDataLoaded = false;
    this._scheduleDataLoaded = false;
    this.logger().info("Start: {0}", [JSON.stringify(this._credentials)]);
  }

  start() {
    this.populateData();
  }

  populateData() {
    this._tableData = [];
    var newData = []
    this.buildRowData(newData)
    this._tableData = newData;
    return this;
  }

  buildRowData(newData) {
    var utils = new SignInRecoveryModelUtils(this.panel(), newData);
    utils.start();
  }

  domain() {
    return this._domain;
  }

  panel() {
    return this._panel;
  }

  logger() {
    return this._logger;
  }

  credentials() {
    return this._credentials;
  }

  withValue(kvp) {
    this.logger().info("Received: {0}", [JSON.stringify(kvp)]);
    this._showMissingRequirements = false;
    if (!kvp) {
      return;
    }

    var field = kvp['key'];
    var fieldValue = kvp['value'];
    var objectId = kvp['id'];
    if (!objectId) {
      return;
    }

    this._credentials.put(field, fieldValue);
  }

  /**
   * Handle buttons being pressed.
   */
  withButtonPressed(buttonData) {
    this.logger().info("Button: {0}", [JSON.stringify(buttonData)]);
    if (!buttonData) {
      return;
    }
    var field = buttonData['field'];
    if (!field) {
      return;
    }
    if (StringUtils.e(SignInRecoveryModel.Fields.SendButton, field)) {
      if (!this.areRequirementsMet()) {
        this._showMissingRequirements = true;
        return;
      }

      this._state = SignInRecoveryModel.Values.States.Sending;
      this.withError({
        messageId: "",
        hint: ""
      });
      this.panel().populateData();
      this.panel().sendDelayed();
    }
    if (StringUtils.e(SignInRecoveryModel.Fields.BackButton, field)) {
      this.panel().$router.replace(
        { name: this.panel().$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN }
      );
    }
  }

  withStatus(status) {
    this._status = status;
  }

  status() {
    return this._status;
  }

  sendIfPossible() {
    this._state = SignInRecoveryModel.Values.States.Sending;
    if (this.panel().auth_connected) {
      this.send();
    } else {
      this.panel().authClear();
      try {
        this.panel().$connect();
      } catch (e) {
        this.error = {
          messageId: "NoConnection",
          hint: "It appears that ReadyChek.cloud is not reachable at the moment"
        }
      }
    }
  }

  send() {
    var error = {
      messageId: "",
      hint: ""
    }
    this.withError(error);
    this._state = SignInRecoveryModel.Values.States.Sending;

    const requestEvent = CredentialEvents.Lost.Request(this.credentials().email());
    EventsDomain.initEvent(requestEvent, null, uuid.v1(), 
      this.panel().auth_client.deviceId, 
      this.panel().auth_client.version);
    EventUtils.Send.event(this.panel().$socket, requestEvent);
    this._state = SignInRecoveryModel.Values.States.Done;
  }

  showMissingRequirements() {
    return this._showMissingRequirements;
  }

  tableData() {
    return this._tableData;
  }

  isStateReady() {
    return StringUtils.e(this._state, SignInRecoveryModel.Values.States.Ready);
  }

  isStateSending() {
    return StringUtils.e(this._state, SignInRecoveryModel.Values.States.Sending);
  }

  isStateDone() {
    return StringUtils.e(this._state, SignInRecoveryModel.Values.States.Done);
  }

  withError(error) {
    this._error = error;
    this._state = SignInRecoveryModel.Values.States.Ready;
    return this;
  }

  withErrorUnableToConnectToApi() {
    var error = {
      messageId: "NoConnectionToApi",
      hint: "It appears that ReadyChek.cloud's API is not reachable, please try again"
    }
    this.withError(error);
    return this;
  }

  error() {
    return this._error;
  }

  areRequirementsMet() {
    var met = true;
    if (met && StringUtils.isEmpty(this.credentials().email())) {
      met = false
    }
    return met;
  }

}







